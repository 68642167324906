import MainPage from "./pages/MainPage";
import {Mobile, PC, Portrait} from "./pages/Responsive";
// import {useEffect} from "react";

// useEffect(() => {
//   window.addEventListener("resize", function () {
//     if (window.matchMedia("(orientation: portrait)").matches) {
//       window.scrollTo(0, 0);
//     } else {
//       window.scrollTo(0, 0);
//     }
//   });
// }, []);

function App() {
  return (
    <>
      <Mobile>
        <MainPage />
      </Mobile>
      <PC>
        <MainPage />
      </PC>
      {/* <Portrait>
        <MainPage />
      </Portrait> */}
    </>
  );
}

export default App;
