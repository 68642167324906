import React from "react";
import {InlineWidget} from "react-calendly";
import {IoIosClose} from "react-icons/io";

export default function Calendly({open, onModalClose}) {
  return (
    <>
      {open && (
        <div className="w-full h-screen flex justify-center items-center absolute z-[1000]">
          <div className="w-[400px] h-[800px] content-center origin-center object-center">
            <button
              onClick={onModalClose}
              className="mb-2 text-[white] float-left"
            >
              <IoIosClose size={40} />
            </button>

            <InlineWidget
              url="https://calendly.com/oopss"
              styles={{width: 400, height: 500}}
              LoadingSpinner={() => (
                <div className="flex justify-center items-center text-center text-[white]">
                  LOADING...
                </div>
              )}
            />
          </div>
        </div>
      )}
    </>
  );
}
