import React from "react";
import emailjs from "emailjs-com";
import {useForm} from "react-hook-form";
import {isMobile} from "react-device-detect";

export default function JPEmailModal({open, onModalClose}) {
  const SERVICE_ID = `${process.env.REACT_APP_EMAILJS_SERVICE_ID}`;
  const TOKYO_TEMPLATE_ID = `${process.env.REACT_APP_EMAILJS_TOKYO_TEMPLATE_ID}`;
  const API_KEY = `${process.env.REACT_APP_EMAILJS_API_PUBLIC_KEY}`;

  const PROFILE_JP =
    "https://www.dropbox.com/scl/fo/lsvcqapmzbwypra3axs18/AE96umBS0Q9AHnBXDIcQy7c?rlkey=r94nr7gl66dbgyhb2xd96dqep&dl=0";

  const {handleSubmit, register} = useForm();
  // console.log("region ==>", region);

  const sendEmail = async (data) => {
    await new Promise((r) => setTimeout(r, 100));
    const templateParam = {
      to_email: data.email,
      message: PROFILE_JP,
    };
    await emailjs
      .send(SERVICE_ID, TOKYO_TEMPLATE_ID, templateParam, API_KEY)
      .then((snapshot) => {
        // console.log(snapshot);
        alert("전송이 완료되었습니다.");
        // window.location.reload();
      })
      .catch((err) => {
        alert("전송이 실패하였습니다.");
        console.log(err);
      });
    await onModalClose();
    window.location.reload();
  };

  return (
    <>
      {open && (
        <div
          className={`w-full h-screen flex justify-center ${
            isMobile ? "items-center mb-[86%]" : "items-center"
          }  absolute z-[999]`}
        >
          {/* <div className="flex items-center justify-center"> */}
          <div
            onClick={onModalClose}
            className="w-full h-screen absolute z-[998]"
          />
          <div className="z-[999] flex items-center justify-center">
            <form onSubmit={handleSubmit(sendEmail)}>
              <input
                type={"email"}
                {...register("email")}
                autoFocus
                required
                placeholder="Enter your email"
                className="w-[200px] h-[60px] rounded-l-md text-[white] px-4 backdrop-blur-xl backdrop-brightness-200 bg-[#ffffff3f]"
              />
              <input
                type="submit"
                value="Submit"
                // onClick={sendEmail}
                className="w-[100px] h-[60px] rounded-r-md backdrop-blur-xl backdrop-brightness-200 bg-[#ffffff3f] font-bold hover:cursor-pointer text-[white] hover:bg-[#ffffff80]"
              />
            </form>
          </div>
          {/* </div> */}
        </div>
      )}
    </>
  );
}
