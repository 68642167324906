import React from "react";
import {useMediaQuery} from "react-responsive";

export const Mobile = ({children}) => {
  const isMobile = useMediaQuery({
    query: "(max-width:768px)",
  });

  return <>{isMobile && children}</>;
};

export const PC = ({children}) => {
  const isPc = useMediaQuery({
    query: "(min-width:900px)",
  });

  return <>{isPc && children}</>;
};
// export const Portrait = ({children}) => {
//   const isPortrait = useMediaQuery({orientation: "portrait"});

//   return <>{isPortrait && children}</>;
// };
